









































import {
  defineComponent, ref, computed, PropType, Ref,
} from '@vue/composition-api';
import { Richtext } from 'storyblok-js-client';
import FCImage from '~/components/FCImage.vue';
import { useStoryblok } from '~/composables/useStoryblok';
import { IStoryblokMedia } from '~/selectors/types';
import { useModal } from '~/composables/useModal';
import { useExpand } from '~/composables/useExpand';

interface IReviewCustomerDataProps {
  codeId: string;
  body: Richtext;
  images: IStoryblokMedia[];
}

export default defineComponent({
  name: 'ReviewCustomerData',
  components: {
    FCImage,
  },
  props: {
    codeId: {
      type: String,
      required: true,
    },
    body: {
      type: Object as PropType<Richtext>,
      required: true,
    },
    images: {
      type: Array as PropType<IStoryblokMedia[]>,
      required: false,
      default: () => [],
    },
  },
  setup(props: IReviewCustomerDataProps) {
    const reviewContentEl = ref(null) as Ref<HTMLDivElement | null>;

    const { renderRichText } = useStoryblok();
    const modifyBody = renderRichText(props.body);

    const showButton = computed(() => {
      if (process.client && reviewContentEl?.value) {
        return reviewContentEl?.value?.clientHeight < reviewContentEl?.value?.scrollHeight;
      }

      return false;
    });

    function openModal(image: IStoryblokMedia) {
      if (image && image?.filename) {
        const width = Math.min(500, Math.floor(window?.innerWidth * 0.85));
        const height = Math.min(700, Math.floor(window?.innerHeight * 0.85));

        const { modalShow } = useModal({
          component: () => import('~/components/storyblok/ImageModal/ImageModal.vue'),
          componentProps: {
            ...image,
            filename: `${image.filename}/m/`,
          },
          modalProps: {
            componentName: 'ImageModal',
            classes: 'modal-border-radius',
            width,
            height,
          },
        });

        modalShow();
      }
    }

    const { isExpanded, toggleExpanded } = useExpand();

    return {
      modifyBody,
      isExpanded,
      reviewContentEl,
      reviewContentClasses: computed(() => ({
        'fc-margin-bottom-7': !showButton,
        expanded: isExpanded.value,
      })),
      expandButtonClass: computed(() => ({ expanded: isExpanded.value })),
      toggleExpanded,
      showButton,
      reviewImgs: props.images?.slice(0, 4) || [],
      openModal,
      showText: computed(() => (isExpanded.value ? 'Read Less' : 'Read More')),
    };
  },
});
